import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Footer from "components/common/Footer/Footer";
import NonAuthNav from "components/common/Navbar/NonAuthNav";
import useProfile from "hooks/useProfile";
import useAuthToken from "hooks/useAuthToken";

export default function NonAuth() {
  const { profile } = useProfile();
  const location = useLocation();
  const { authToken } = useAuthToken();

  const isEmailVerificationRoute = location.pathname === "/email-verification";
  const isPhoneVerificationRoute = location.pathname === "/phone-verification";

  if (!authToken) {
    return <Navigate to="/" replace />;
  }

  if (
    profile?.email_confirmed &&
    profile?.phone_number &&
    (isEmailVerificationRoute || isPhoneVerificationRoute)
  ) {
    return <Navigate to="/" />;
  }

  if (!profile?.email_confirmed && !isEmailVerificationRoute) {
    return <Navigate to="/email-verification" replace />;
  }

  if (
    profile?.email_confirmed &&
    !profile?.phone_number &&
    !isPhoneVerificationRoute
  ) {
    return <Navigate to="/phone-verification" replace />;
  }
  return (
    <React.Fragment>
      <NonAuthNav />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}
