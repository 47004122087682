import React, { useState } from "react";
import { RecoilRoot } from "recoil";
import { GenIcon } from "react-icons/lib";
import "react-toastify/dist/ReactToastify.css";
import Fab from "@mui/material/Fab";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { TbRobotOff } from "react-icons/tb";

import Chatbot from "./components/chatbot/chatbot";

import ProtectedRoutes from "./components/RequireAuth/ProtectedRoutes";

// import { GoDependabot } from "react-icons/go";
import PageLoader from "./utils/PageLoader";
import "./App.css";

import AuthLayout from "layouts/AuthLayout";
import NavigationLayout from "layouts/NavigationLayout";
import NonAuthLayout from "layouts/NonAuth";
import useSendEvent from "hooks/useSendEvent";

// import Certification from "./components/NewDashboard/StudentDashboard/Certification";

// lazy PageLoader
const Home = React.lazy(() => import("./pages/Home"));
const JobPost = React.lazy(() => import("./components/hrDashboard/JobPost"));
const Talents = React.lazy(() => import("./components/hrDashboard/Talents"));
const JobDetails = React.lazy(() => import("./pages/JobDetails"));
const JoinCommunity = React.lazy(() => import("./pages/JoinCommunity"));
const Certification = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Certification")
);
const Certificate = React.lazy(() => import("pages/Certificate/Certificate"));
const EidOffer = React.lazy(() => import("pages/EidOffer/EidOffer"));

// student dashboard
const ResourceDownload = React.lazy(() =>
  import("pages/ResourceDownload/ResourceDownload")
);
const Index = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Index")
);
const NewCourses = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/NewCourses")
);

const QueryDetails = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/QueryDetails")
);
const Quizes = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Quizes")
);
const Grades = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Grades")
);
const Schedule = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Schedule")
);
const Rating = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Rating")
);
const Profile = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Profile")
);
const Query = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Query")
);
const CourseAnnouncement = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/CourseAnnouncement")
);
const LiveChat = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/LiveChat")
);
const Community = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/Community")
);
const NewWishlist = React.lazy(() =>
  import("./components/NewDashboard/StudentDashboard/NewWishlist")
);

const RecordedMasterclass = React.lazy(() =>
  import("./pages/RecordedMasterclass/RecordedMasterclass")
);

const AffiliationPage = React.lazy(() => import("./pages/AffiliationPage"));

const CreateCv = React.lazy(() => import("./pages/CreateCv"));
const UpdateProfile = React.lazy(() =>
  import("./components/jobseekers/UpdateProfile/UpdateProfile")
);
const AvailableJobs = React.lazy(() =>
  import("./components/jobseekers/AvailableJobs/AvailableJobs")
);
const AppliedJobs = React.lazy(() =>
  import("./components/jobseekers/AppliedJobs/AppliedJobs")
);
const Affiliation = React.lazy(() =>
  import("./components/NewDashboard/AffiliateDashboard/Affiliation")
);
const JsProfile = React.lazy(() =>
  import("./components/jobseekers/UpdateProfile/Profile/JsProfile")
);
const JsJobExp = React.lazy(() =>
  import("./components/jobseekers/UpdateProfile/JobExp/JsJobExp")
);
const JsEducation = React.lazy(() =>
  import("./components/jobseekers/UpdateProfile/Education/JsEducation")
);
const JsSecurity = React.lazy(() =>
  import("./components/jobseekers/UpdateProfile/Security/JsSecurity")
);
const HrUpdateProfile = React.lazy(() =>
  import("./components/hrDashboard/UpdateProfile")
);
const AllJobsPage = React.lazy(() => import("./pages/AllJobsPage"));

const ShortListedData = React.lazy(() =>
  import("./components/hrDashboard/ShortListedData/ShortListedData")
);

const EmailVerification = React.lazy(() =>
  import("./components/userInformation/Auth/EmailVerification")
);

const Bofu = React.lazy(() => import("./pages/BofuPage/Bofu"));

const HiringPage = React.lazy(() => import("./pages/HiringPage"));
const BuisnessPage = React.lazy(() => import("./pages/BusinessPage"));
const Courses = React.lazy(() => import("./pages/Courses"));
const AllCareerPath = React.lazy(() => import("./pages/AllCareerPathPage"));
const CarrerPage = React.lazy(() => import("./pages/CarrerPage"));
const AboutPage = React.lazy(() => import("./pages/AboutPage"));
const JobDetailsPage = React.lazy(() => import("./pages/JobDetailsPage"));
const Login = React.lazy(() =>
  import("./components/userInformation/Auth/Login")
);

const Register = React.lazy(() =>
  import("./components/userInformation/Auth/Register")
);

const SupportSession = React.lazy(() => import("./pages/SupportSession"));
const VerifyEmail = React.lazy(() =>
  import("./components/userInformation/Auth/VerifyEmail")
);
const JobSeekers = React.lazy(() =>
  import("./components/jobseekers/JobSeekers/JobSeekers")
);

const DevelopmentPage = React.lazy(() => import("./pages/DevelopmentPage"));
const PaymentPage = React.lazy(() => import("./pages/PaymentPage"));
const Success = React.lazy(() => import("./components/paymentSystem/Success"));
const Failed = React.lazy(() => import("./components/paymentSystem/Failed"));
const Cancel = React.lazy(() => import("./components/paymentSystem/Cancel"));
const Error = React.lazy(() => import("./components/common/404/Error"));
const Terms = React.lazy(() => import("./pages/Terms"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));

/* Auth pages */
const LostPassword = React.lazy(() =>
  import("./components/userInformation/Auth/LostPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/userInformation/Auth/ResetPassword")
);
const CoursePlayerPage = React.lazy(() =>
  import("./pages/coursePlayer/CoursePlayerPage")
);

/* Others */
const BlogsPage = React.lazy(() => import("./pages/BlogsPage"));
const BlogsDetailsPage = React.lazy(() => import("./pages/BlogsDetailsPage"));

// Cv generator
const CvGeneratorLayout = React.lazy(() =>
  import("./layouts/CvGeneratorLayout")
);
const BasicInformation = React.lazy(() =>
  import("./components/CVGenerator/BasicInformation/BasicInformation")
);
const CvProject = React.lazy(() =>
  import("./components/CVGenerator/CvProject/CvProject")
);
const Experience = React.lazy(() =>
  import("./components/CVGenerator/Experience/Experience")
);
const CvEducation = React.lazy(() =>
  import("./components/CVGenerator/CvEducation/CvEducation")
);
const CvSkills = React.lazy(() =>
  import("./components/CVGenerator/CvSkills/CvSkills")
);
const CvSummery = React.lazy(() =>
  import("./components/CVGenerator/CvSummery/CvSummery")
);
const Finalize = React.lazy(() =>
  import("./components/CVGenerator/Finalize/Finalize")
);

const LiveQuizPage = React.lazy(() => import("./pages/LiveQuizPage"));

const ResumeSorter = React.lazy(() =>
  import("./components/hrDashboard/ResumeSorter/ResumeSorter")
);

const AllResume = React.lazy(() =>
  import("./components/hrDashboard/ResumeSorter/AllResume")
);
const ReportIssuePage = React.lazy(() => import("./pages/ReportIssuePage"));
const CoverLetter = React.lazy(() =>
  import("./components/CVGenerator/CoverLetter/CoverLetter")
);

const AffiliateProfile = React.lazy(() =>
  import("./components/NewDashboard/AffiliateDashboard/AffiliateProfile")
);
const AffiliatePayment = React.lazy(() =>
  import("./components/NewDashboard/AffiliateDashboard/AffiliatePayment")
);

const Affiliate = React.lazy(() => import("pages/Affiliate"));
const JobSeeker = React.lazy(() => import("pages/JobSeeker"));
const Recruiter = React.lazy(() => import("pages/Recruiter"));
const Instructor = React.lazy(() => import("pages/Instructor"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const ConvertInstructor = React.lazy(() => import("pages/Instructor/Form"));
const ConvertRecruiter = React.lazy(() => import("pages/Recruiter/Form"));

const UpcomingMasterclass = React.lazy(() =>
  import("pages/UpcomingWebinar/UpcomingWebinar")
);

const ModifiedCourseDetails = React.lazy(() =>
  import("./pages/ModifiedCourseDetails")
);

const ModifiedCareerPath = React.lazy(() =>
  import("./pages/ModifiedCareerPath")
);

// instructor routes

const Overview = React.lazy(() =>
  import("./components/IrDashboard/Overview/Overview")
);
const AddCourse = React.lazy(() =>
  import("./components/IrDashboard/AddCourse/AddCourse")
);
const IrCourseDetails = React.lazy(() =>
  import("./components/IrDashboard/IrCourseDetails/IrCourseDetails")
);
const MyCourses = React.lazy(() =>
  import("./components/IrDashboard/MyCourses/MyCourses")
);

const IrEditCourse = React.lazy(() =>
  import("./components/IrDashboard/IrEditCourse/IrEditCourse")
);

const IrUpdateProfile = React.lazy(() =>
  import("./components/IrDashboard/IrUpdateProfile/IrUpdateProfile")
);

const Account = React.lazy(() =>
  import("./components/IrDashboard/IrUpdateProfile/Account/Account")
);

const Security = React.lazy(() =>
  import("./components/IrDashboard/IrUpdateProfile/Security/Security")
);

const IrAchievements = React.lazy(() =>
  import(
    "./components/IrDashboard/IrUpdateProfile/IrAchievements/IrAchievements"
  )
);

const IrEducations = React.lazy(() =>
  import("./components/IrDashboard/IrUpdateProfile/IrEducation/IrEducation")
);

const McFacebookFormPage = React.lazy(() =>
  import("./pages/McFacebookFormPage/McFacebookFormPage")
);

const McFacebookFormPageSuccess = React.lazy(() =>
  import("./pages/McFacebookFormPage/McFacebookFormSuccessPage")
);

const PhoneVerification = React.lazy(() =>
  import("components/userInformation/Auth/PhoneVerification")
);

const GoDependabot = function GoDependabot(props) {
  return GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 24 24" },
    child: [
      {
        tag: "path",
        attr: {
          d: "M8.75 11a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Zm7.25.75a.75.75 0 0 0-1.5 0v3.5a.75.75 0 0 0 1.5 0v-3.5Z",
        },
      },
      {
        tag: "path",
        attr: {
          d: "M9.813 1h2.437a.75.75 0 0 1 .75.75V5h6.75A2.25 2.25 0 0 1 22 7.25v5.25h1.25a.75.75 0 0 1 0 1.5H22v5.75A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.75V14H.75a.75.75 0 0 1 0-1.5H2V7.25A2.25 2.25 0 0 1 4.25 5h7.25V2.5H9.813A.75.75 0 0 1 9.812 1ZM3.5 7.25v12.5c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V7.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75Z",
        },
      },
    ],
  })(props);
};

const TbRobotOff = function TbRobotOff(props) {
  return GenIcon({
    tag: "svg",
    attr: {
      viewBox: "0 0 24 24",
      strokeWidth: "2",
      stroke: "currentColor",
      fill: "none",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    child: [
      {
        tag: "path",
        attr: { stroke: "none", d: "M0 0h24v24H0z", fill: "none" },
      },
      {
        tag: "path",
        attr: {
          d: "M8 4h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2m-4 0h-4a2 2 0 0 1 -2 -2v-4",
        },
      },
      { tag: "path", attr: { d: "M12 2v2" } },
      { tag: "path", attr: { d: "M9 12v9" } },
      { tag: "path", attr: { d: "M15 15v6" } },
      { tag: "path", attr: { d: "M5 16l4 -2" } },
      { tag: "path", attr: { d: "M9 18h6" } },
      { tag: "path", attr: { d: "M14 8v.01" } },
      { tag: "path", attr: { d: "M3 3l18 18" } },
    ],
  })(props);
};

function App() {
  const [isChatboxVisible, setChatboxVisibility] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [adjustHeight, setAdjustHeight] = useState(false);
  const { sendEvent } = useSendEvent();

  const [openPromo, setOpenPromo] = useState(true);
  const [promoImage, setImage] = useState(null);

  const handleChatboxToggle = () => {
    sendEvent("chatbot", "chatbot");
    setChatboxVisibility(!isChatboxVisible);
  };

  return (
    <div className="app mt-[-25px]">
      <Router>
        <Routes>
          {/* new master class page */}
          <Route
            path="/masterclass-details/:id"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <UpcomingMasterclass />
              </React.Suspense>
            }
          />
          <Route element={<NonAuthLayout />}>
            <Route
              path="/phone-verification"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <PhoneVerification />
                </React.Suspense>
              }
            />

            <Route
              path="/email-verification"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <EmailVerification />
                </React.Suspense>
              }
            />
          </Route>

          <Route element={<NavigationLayout />}>
            <Route
              path="/eid-offer"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <EidOffer />
                </React.Suspense>
              }
            />
            <Route
              path="/handbook/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ResourceDownload />
                </React.Suspense>
              }
            />
            <Route
              path="/course-bofu/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Bofu />
                </React.Suspense>
              }
            />
            <Route
              path="/certificate/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Certificate />
                </React.Suspense>
              }
            />
            <Route
              path="/register"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Register />
                </React.Suspense>
              }
            />
            <Route
              path="/reset-password/"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ResetPassword />
                </React.Suspense>
              }
            />
            <Route
              path="/login"
              element={
                <>
                  <React.Suspense fallback={<PageLoader />}>
                    <AuthLayout>
                      <Login />
                    </AuthLayout>
                  </React.Suspense>
                </>
              }
            />
            <Route
              path="/lost-password"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <LostPassword />
                </React.Suspense>
              }
            />
            <Route
              path="/resume/*"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <CvGeneratorLayout />
                </React.Suspense>
              }
            >
              <Route
                index
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <CreateCv />
                  </React.Suspense>
                }
              />
              <Route
                path="information/:resumeid"
                element={<BasicInformation />}
              />
              <Route path="experience/:resumeid" element={<Experience />} />
              <Route path="projects/:resumeid" element={<CvProject />} />
              <Route path="skills/:resumeid" element={<CvSkills />} />
              <Route path="education/:resumeid" element={<CvEducation />} />
              <Route path="summary/:resumeid" element={<CvSummery />} />
              <Route path="finalize/:resumeid" element={<Finalize />} />
            </Route>
            {/* public routes */}
            <Route
              exact
              path="/"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Home
                    setChatboxVisibility={setChatboxVisibility}
                    setOpenPromo={setOpenPromo}
                    openPromo={openPromo}
                    promoImage={promoImage}
                    setImage={setImage}
                  />
                </React.Suspense>
              }
            />
            {/* General user */}

            <Route
              path="/development"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <DevelopmentPage />
                </React.Suspense>
              }
            />

            <Route
              path="/courseDetails/:coursesId/affiliate/:affiliateId"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ModifiedCourseDetails
                    isChatboxVisible={isChatboxVisible}
                    setAdjustHeight={setAdjustHeight}
                  />
                </React.Suspense>
              }
            />

            <Route path="/chatbot" element={<Chatbot />} />

            <Route
              path="/log_reg/activate/:uidb64/:token"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <VerifyEmail />
                </React.Suspense>
              }
            />
            <Route
              path="/allcourses"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Courses
                    setOpenPromo={setOpenPromo}
                    openPromo={openPromo}
                    promoImage={promoImage}
                    setImage={setImage}
                  />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/support-session"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <SupportSession setChatboxVisibility={setChatboxVisibility} />
                </React.Suspense>
              }
            />
            {/* ========================================================= */}
            <Route
              path="/hiringpage"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <HiringPage />
                </React.Suspense>
              }
            />
            <Route
              path="/business"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <BuisnessPage />
                </React.Suspense>
              }
            />
            <Route
              path="/career-paths/:coursesId"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ModifiedCareerPath
                    isChatboxVisible={isChatboxVisible}
                    setAdjustHeight={setAdjustHeight}
                  />
                  {/* <CareerPathsPage/> */}
                </React.Suspense>
              }
            />

            <Route
              path="/jobseekers"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <JobSeekers />
                </React.Suspense>
              }
            />
            {/* Payment pages */}
            <Route
              path="/payment/:id/:affiliateId?"
              element={
                <ProtectedRoutes type={3}>
                  <React.Suspense fallback={<PageLoader />}>
                    <PaymentPage />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            />

            <Route
              path="/payment/success/:courseId/:token"
              element={
                <ProtectedRoutes type={3}>
                  <React.Suspense fallback={<PageLoader />}>
                    <Success />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/payment/failed/:token"
              element={
                <ProtectedRoutes type={3}>
                  <React.Suspense fallback={<PageLoader />}>
                    <Failed />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/payment/cancel/:token"
              element={
                <ProtectedRoutes type={3}>
                  <React.Suspense fallback={<PageLoader />}>
                    <Cancel />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            />
            <Route
              path="/hr-dashboard/*"
              element={
                <ProtectedRoutes type={1}>
                  <React.Suspense fallback={<PageLoader />}>
                    {/* <HrDashboard /> */}
                    <Recruiter />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            >
              <Route index element={<JobPost />} />
              <Route
                path="job-details"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <JobDetails />
                  </React.Suspense>
                }
              />
              <Route
                path="talents"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Talents />
                  </React.Suspense>
                }
              />
              <Route path="sort-resume" element={<ResumeSorter />} />
              <Route path="filter-resume/:id" element={<AllResume />} />
              {/* <Route path="shortlisteddata/:id" element={<ShortListedData />} /> */}
              <Route
                path="shortlisteddata/"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <ShortListedData />
                  </React.Suspense>
                }
              />
              <Route
                path="update-profile"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <HrUpdateProfile />
                  </React.Suspense>
                }
              />
            </Route>

            <Route
              path="/convert-instructor"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ConvertInstructor />
                </React.Suspense>
              }
            />

            <Route
              path="/convert-recruiter"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ConvertRecruiter />
                </React.Suspense>
              }
            />

            <Route
              path="/ir-dashboard/*"
              element={
                <ProtectedRoutes type={2}>
                  <React.Suspense fallback={<PageLoader />}>
                    {/* <LazyNewDashboard type="instructor" /> */}
                    <Instructor />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            >
              <Route
                index
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Overview />
                  </React.Suspense>
                }
              />

              <Route
                path="add-courses"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <AddCourse />
                  </React.Suspense>
                }
              />
              <Route
                path="job-details"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <JobDetails />
                  </React.Suspense>
                }
              />

              <Route
                path="my-courses"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <MyCourses />
                  </React.Suspense>
                }
              />
              <Route
                path="course-details/:id"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <IrCourseDetails />
                  </React.Suspense>
                }
              />

              <Route
                path="edit-courses/:id"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <IrEditCourse />
                  </React.Suspense>
                }
              />
              <Route
                path="update-profile"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <IrUpdateProfile />
                  </React.Suspense>
                }
              >
                <Route
                  index
                  element={
                    <React.Suspense fallback={<PageLoader />}>
                      <Account />
                    </React.Suspense>
                  }
                />
                <Route
                  path="account"
                  element={
                    <React.Suspense fallback={<PageLoader />}>
                      <Security />
                    </React.Suspense>
                  }
                />

                <Route
                  path="achievements"
                  element={
                    <React.Suspense fallback={<PageLoader />}>
                      <IrAchievements />
                    </React.Suspense>
                  }
                />
                <Route
                  path="education"
                  element={
                    <React.Suspense fallback={<PageLoader />}>
                      <IrEducations />
                    </React.Suspense>
                  }
                />
              </Route>

              {/* children */}
            </Route>

            <Route
              path="/js-dashboard/*"
              element={
                <ProtectedRoutes type={5}>
                  <RecoilRoot>
                    <React.Suspense fallback={<PageLoader />}>
                      {/* <LazyNewDashboard type="jobseeker" /> */}
                      <JobSeeker />
                    </React.Suspense>
                  </RecoilRoot>
                </ProtectedRoutes>
              }
            >
              <Route index element={<JobSeekers />} />
              <Route path="available-jobs" element={<AvailableJobs />} />
              <Route path="applied-jobs" element={<AppliedJobs />} />

              <Route
                path="available-jobs/job-details"
                element={<JobDetails />}
              />
              <Route path="latest-jobs/job-details" element={<JobDetails />} />
              <Route path="applied-jobs/job-details" element={<JobDetails />} />
              <Route path="resume" element={<CvGeneratorLayout />}>
                <Route index element={<CreateCv />} />
                <Route
                  path="information/:resumeid"
                  element={<BasicInformation />}
                />
                <Route path="experience/:resumeid" element={<Experience />} />
                <Route path="projects/:resumeid" element={<CvProject />} />
                <Route path="skills/:resumeid" element={<CvSkills />} />
                <Route path="education/:resumeid" element={<CvEducation />} />
                <Route path="summary/:resumeid" element={<CvSummery />} />
                <Route
                  path="cover-letter/:resumeid"
                  element={<CoverLetter />}
                />
                <Route path="finalize/:resumeid" element={<Finalize />} />
              </Route>
              <Route path="update-profile" element={<UpdateProfile />}>
                <Route index element={<JsProfile />} />
                <Route path="job-experience" element={<JsJobExp />} />
                <Route path="education" element={<JsEducation />} />
                <Route path="security" element={<JsSecurity />} />
              </Route>
            </Route>
            {/* Affiliation pages */}
            <Route
              path="/affiliation"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <AffiliationPage />
                </React.Suspense>
              }
            />

            <Route
              path="lessons/:id"
              element={
                <ProtectedRoutes type={3}>
                  <React.Suspense fallback={<PageLoader />}>
                    <CoursePlayerPage />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            />
            {/* New student dashboard */}
            <Route path="/new-password"></Route>
            {/* New student dashboard ends*/}
            {/* Career pages */}
            <Route
              path="/career"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <CarrerPage />
                </React.Suspense>
              }
            />
            <Route
              path="/jobs"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <AllJobsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/jobs/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <JobDetailsPage />
                </React.Suspense>
              }
            />
            {/* ============ */}
            <Route
              path="/blogs"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <BlogsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/blogs/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <BlogsDetailsPage />
                </React.Suspense>
              }
            />

            {/* New student dashboard */}
            <Route
              path="/newDashboard"
              element={
                <ProtectedRoutes type={3}>
                  <React.Suspense fallback={<PageLoader />}>
                    <Dashboard />
                  </React.Suspense>
                </ProtectedRoutes>
              }
            >
              <Route
                index
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Index />
                  </React.Suspense>
                }
              />
              <Route
                path="courses"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <NewCourses />
                  </React.Suspense>
                }
              />
              <Route
                path="quiz"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Quizes />
                  </React.Suspense>
                }
              />
              <Route
                path="grades"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Grades />
                  </React.Suspense>
                }
              />
              <Route
                path="schedule"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Schedule />
                  </React.Suspense>
                }
              />
              <Route
                path="certificates"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Certification />
                  </React.Suspense>
                }
              />
              <Route
                path="rating"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Rating />
                  </React.Suspense>
                }
              />
              <Route
                path="wishlist"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <NewWishlist />
                  </React.Suspense>
                }
              />
              <Route
                path="profile"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Profile />
                  </React.Suspense>
                }
              />
              <Route
                path="query"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Query />
                  </React.Suspense>
                }
              />
              <Route
                path="query-details/:id"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <QueryDetails />
                  </React.Suspense>
                }
              />
              <Route
                path="course-announcement"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <CourseAnnouncement />
                  </React.Suspense>
                }
              />
              <Route
                path="live-chat"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <LiveChat />
                  </React.Suspense>
                }
              />
              <Route
                path="community"
                element={
                  <React.Suspense fallback={<PageLoader />}>
                    <Community />
                  </React.Suspense>
                }
              />
            </Route>
            {/* New student dashboard ends*/}
            {/* New affiliate dashboard */}
            <Route
              path="/affiliate-dashboard"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ProtectedRoutes type={4}>
                    <Affiliate />
                  </ProtectedRoutes>
                </React.Suspense>
              }
            >
              <Route index element={<Affiliation />} />
              <Route path="profile" element={<AffiliateProfile />} />
              <Route path="payments" element={<AffiliatePayment />} />
            </Route>
            {/* New affiliate dashboard ends */}
            <Route path="/new-password"></Route>
            {/* Career pages */}
            <Route
              path="/career"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <CarrerPage />
                </React.Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <AboutPage />
                </React.Suspense>
              }
            />
            <Route
              path="/jobs"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <AllJobsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/jobs/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <JobDetailsPage />
                </React.Suspense>
              }
            />
            {/* ============ */}
            <Route
              path="/blogs"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <BlogsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/allCareerPath"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <AllCareerPath />
                </React.Suspense>
              }
            />
            <Route
              path="/blogs/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <BlogsDetailsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/quiz/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <LiveQuizPage />
                </React.Suspense>
              }
            />

            <Route
              path="*"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Error />
                </React.Suspense>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <Terms />
                </React.Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <PrivacyPolicy />
                </React.Suspense>
              }
            />
            {/* <Route path="/quiz" element={<Quiz />} /> */}
            <Route
              path="/report-issue"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ReportIssuePage />
                </React.Suspense>
              }
            />

            <Route
              path="/masterclass-recorded/:id"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <RecordedMasterclass setAdjustHeight={setAdjustHeight} />
                </React.Suspense>
              }
            />

            <Route
              path="/join-group"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <JoinCommunity />
                </React.Suspense>
              }
            />

            <Route
              path="/courseDetails/:coursesId"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <ModifiedCourseDetails
                    isChatboxVisible={isChatboxVisible}
                    setAdjustHeight={setAdjustHeight}
                  />
                </React.Suspense>
              }
            />

            <Route
              path="/register-form/:masterclassId"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <McFacebookFormPage />
                </React.Suspense>
              }
            />
            <Route
              path="/register-form/:masterclassId/success"
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <McFacebookFormPageSuccess />
                </React.Suspense>
              }
            />
          </Route>
        </Routes>

        <div>
          {isChatboxVisible && (
            <div
              className={`${
                expanded
                  ? "w-full h-full fixed top-0 z-[4000]"
                  : "w-[330px] md:w-[400px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px] z-[4000] bg-white h-[400px] fixed bottom-[180px] md:bottom-[100px] lg:bottom-[100px] xl:bottom-[100px] 2xl:bottom-[100px] right-[10px] md:right-[50px] lg:right-[50px] xl:right-[50px] 2xl:right-[50px] rounded-[30px]"
              }`}
            >
              <Chatbot setExpanded={setExpanded} expanded={expanded} />
            </div>
          )}
        </div>
      </Router>

      <div
        className={`${
          adjustHeight
            ? "fixed bottom-[135px] md:bottom-10 lg:bottom-10 xl:bottom-10 2xl:bottom-10 right-2 md:right-5 lg:right-5 xl:right-5 2xl:right-5 z-[150]"
            : "fixed bottom-10 right-5 md:right-[10] lg:right-[10] xl:right-[10] 2xl:right-[10] z-[4000]"
        }`}
      >
        <Fab style={{ backgroundColor: "#0B70C0" }}>
          {!!isChatboxVisible ? (
            <TbRobotOff
              className="w-8 h-8 text-white"
              onClick={handleChatboxToggle}
            />
          ) : (
            <GoDependabot
              className="w-8 h-8 text-white"
              onClick={handleChatboxToggle}
            />
          )}
        </Fab>
      </div>
    </div>
  );
}

export default App;
