import Api from "./api";

const api = new Api();

export const getUser = async (id) => {
  return await api.get(`log_reg/users/${id}/`);
};

export const getWhishList = async () => {
  return await api.get(`/user/wishlist/`);
};

export const addWishList = async (id) => {
  return await api.post(`/user/wishlist/${id}/`);
};

export const deleteWishList = async (id) => {
  return await api.delete(`/user/wishlist/${id}/`);
};

export const getFreeEnroll = async (data) => {
  return await api.post(`marketing/free-enrollment/`, data);
};

export const getJobSeeker = async (id) => {
  return await api.get(`/user/jobseeker-exists/${id}/`);
};

export const getInstructor = async (id) => {
  return await api.get(`/user/instructor-exists/${id}/`);
};

export const getRecruiter = async (id) => {
  return await api.get(`/user/recruiter-exists/${id}/`);
};

export const createInstructorProfile = async (params) => {
  return await api.post("/user/api/v2/instructor/", params);
};

export const createRecruiterProfile = async (params) => {
  return await api.post("/user/api/v2/recruiter/", params);
};

export const createJobSeekerProfile = async () => {
  return await api.post("/user/api/v2/job_seeker/");
};

export const getJobPost = async ({ id, params }) => {
  return await api.get(`/jobs/jobpost/?search=${id}`, params);
};

export const allapplicants = async (id) => {
  return await api.get(`/jobs/allapplicants/${id}/`);
};

export const deleteJob = async (id) => {
  return await api.delete(`/jobs/jobpost/${id}/`);
};

export const getCompletedCourses = async () => {
  return await api.get("course/completed-courses/");
};

export const courseReviews = async (data) => {
  return await api.post("course/reviews/", data);
};

export const certifyUser = async (data) => {
  return await api.post("course/certify-user/", data);
};

export const getCertifiedUsers = async ({ id, data }) => {
  return await api.get(`course/certificate-details/${id}/`, data);
};

export const sendInfo = async ({ id, source }) => {
  return await api.get(`log_reg/save-phone-number/${id}/?source=${source}`);
};
