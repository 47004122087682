import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaBorderAll } from "react-icons/fa";
import { MdBusiness } from "react-icons/md";
import { BsInfoSquare } from "react-icons/bs";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { GoBriefcase } from "react-icons/go";
import { HiRectangleGroup } from "react-icons/hi2";
import { TbAffiliate } from "react-icons/tb";
import { MdOutlineBrowserNotSupported } from "react-icons/md";
import { SiElectronbuilder } from "react-icons/si";
import { SiMicrodotblog } from "react-icons/si";
import logo from "assets/logo/ic-logo.webp";
import GooglePlay from "assets/google-play.svg";

export default function ResponsiveDrawer({ opendrawer, onClose }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const list = (anchor) => (
    <div className="w-[250px] h-full relative" onKeyDown={onClose}>
      <List>
        <div className="navBarAccordion flex flex-col w-full p-5">
          <div className="flex justify-center pb-5">
            <img src={logo} className="w-20 h-auto" alt="" />
          </div>

          <Link to="/eid-offer" onClick={onClose}>
            <div className="relative my-1">
              <div
                className="relative px-4 py-3 text-white rounded-xl overflow-hidden 
    border-2 border-yellow-500 shadow-lg 
    bg-gradient-to-r from-indigo-800 via-slate-800 to-indigo-900
    hover:from-indigo-900 hover:to-slate-800 
    transform hover:scale-105 transition-all duration-300"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-yellow-400/30 to-indigo-500/30 opacity-50 blur-sm"></div>

                <div className="absolute top-0 left-1/4 w-1 h-1 bg-yellow-300 rounded-full animate-ping"></div>
                <div
                  className="absolute bottom-1/3 right-1/4 w-1 h-1 bg-yellow-300 rounded-full animate-ping"
                  style={{ animationDelay: "0.5s" }}
                ></div>
                <div
                  className="absolute top-1/2 right-1/3 w-1 h-1 bg-indigo-300 rounded-full animate-ping"
                  style={{ animationDelay: "1s" }}
                ></div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <span className="text-yellow-300 font-bold text-lg">
                      ঈদ সালামি
                    </span>
                    <span className="text-xs text-indigo-200">
                      Special discounts inside!
                    </span>
                  </div>

                  <div className="bg-yellow-500 p-1 rounded-full text-indigo-900 animate-bounce">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full animate-pulse">
                Limited!
              </div>
            </div>
          </Link>
          <div className="flex flex-col items-start gap-3 mt-3">
            <Link to="/" className="flex gap-2 items-center" onClick={onClose}>
              <IoHomeOutline />
              <h3 className="text-[18px] ">Home</h3>
            </Link>

            <Link
              to="/allcourses"
              className="flex gap-2 items-center"
              onClick={onClose}
            >
              <FaBorderAll />
              <h3 className="text-[18px] ">All Courses</h3>
            </Link>

            <Link
              to="/business"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <MdBusiness />
              Business
            </Link>

            <Link
              to="/allCareerPath"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <MdOutlineBusinessCenter />
              Career Path
            </Link>

            <Link
              to="/about"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <BsInfoSquare />
              About Us
            </Link>

            <Divider
              sx={{ borderStyle: "dotted", marginTop: 2, width: "100%" }}
            />

            <div className="text-[#6a6f73] font-semibold">
              More from interactive
            </div>

            <Link
              to="/career"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <GoBriefcase />
              Career
            </Link>

            <Link
              to="/hiringpage"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <HiRectangleGroup />
              Hire
            </Link>

            <Link
              to="/affiliation"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <TbAffiliate />
              Affiliate
            </Link>

            <Link
              to="/support-session"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <MdOutlineBrowserNotSupported />
              Support Session
            </Link>

            <Link
              to="/js-dashboard/resume"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <SiElectronbuilder />
              Resume Builder
            </Link>

            <Link
              to="/blogs"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <SiMicrodotblog />
              Blogs
            </Link>
            <div className="flex flex-col items-start">
              <dt className="mb-3 font-semibold text-[#6a6f73]">
                Download our App
              </dt>
              <div className="group">
                <a
                  href="https://play.google.com/store/apps/details?id=com.interactivecares.app&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-6 py-3 bg-[#40405C] text-white rounded-xl"
                >
                  <img src={GooglePlay} className="w-8 h-8" alt="google play" />
                  <div className="flex flex-col">
                    <span className="text-xs">GET IT ON</span>
                    <span className="font-semibold">Google Play</span>
                  </div>
                </a>
              </div>
            </div>

            {/* <div className="my-1  p-2 rounded-lg ml-[-20px]">
              <Accordion sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary
                  expandIcon={
                    <BsArrowsAngleExpand style={{ marginLeft: "5px" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      listStyle: "underline",
                      paddingLeft: "12px",
                    }}
                  >
                    More
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col">
                    <ul>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link to="/career" className=" text-[18px] underline">
                          Career
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/hiringpage"
                          className=" text-[18px] underline"
                        >
                          Hire
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/affiliation"
                          className=" text-[18px] underline"
                        >
                          Affiliate
                        </Link>
                      </li>

                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/support-session"
                          className=" text-[18px] underline"
                        >
                          Support Session
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/js-dashboard/resume"
                          className=" text-[18px] underline"
                        >
                          Resume Builder
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link to="/blogs" className=" text-[18px] underline">
                          Blogs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div> */}
          </div>
        </div>
      </List>
    </div>
  );

  return (
    <div className=" ">
      <Drawer
        anchor="left"
        open={opendrawer}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: "#ffffff",
          },
        }}
      >
        {list("left")}
      </Drawer>
    </div>
  );
}
