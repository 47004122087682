import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { FaGoogle } from "react-icons/fa";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import auth from "firebase.init";

import api from "api";
import useAuthToken from "hooks/useAuthToken";
import useProfile from "hooks/useProfile";

export const AuthModal = ({ onClose, demoVideo }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();
  const { setAuthToken } = useAuthToken();
  const { setProfile } = useProfile();

  const provider = new GoogleAuthProvider();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { mutate: googleLogin, isLoading: isGoogleLoading } = useMutation({
    mutationFn: api.auth.loginGoogle,
    onSuccess: (data) => {
      const user = data?.user;
      setProfile(user);
      setAuthToken(data?.access);
      localStorage.setItem("access", data?.access);
      localStorage.setItem("refresh", data?.refresh);
      localStorage.setItem("userId", data?.user.id);
      if (data?.new_user) {
        navigate("/phone-verification");
      }
      onClose();
    },
    onError: (err) => {
      setError(err?.message);
    },
  });

  const { mutate: login, isLoading } = useMutation({
    mutationFn: api.auth.login,
    onSuccess: (data) => {
      const user = data?.user;
      setAuthToken(data?.access);
      setProfile(user);
      localStorage.setItem("access", data?.access);
      localStorage.setItem("refresh", data?.refresh);
      localStorage.setItem("userId", user.id);
      onClose();
    },
    onError: (err) => {
      setError(err?.message);
    },
  });

  const handleGoogleSignInUp = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      if (result?.user?.emailVerified) {
        googleLogin(result?.user.accessToken);
      }
      onClose();
    } catch (err) {}
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    if (!username) {
      setError("Please enter your email/username");
      return;
    }
    if (!password) {
      setError("Please enter your password");
      return;
    }

    login({
      username: username,
      password: password,
    });
  };

  useEffect(() => {
    setError(null);
  }, []);

  const handleNavigate = () => {
    const searchQuery = location.search ? location.search : "";
    navigate(`/register${searchQuery}`);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open
      PaperProps={{
        sx: {
          bgcolor: "#F1F5F9",
          borderRadius: { md: 8, xs: 4 },
          maxWidth: "400px",
          width: "100%",
          position: "relative",
        },
      }}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
        },
      }}
    >
      <button
        onClick={onClose}
        className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
        style={{
          position: "absolute",
          top: "16px",
          right: "24px",
          cursor: "pointer",
          background: "none",
          border: "none",
        }}
      >
        <IoMdClose className="text-xl" />
      </button>
      <Box
        sx={{
          px: { xs: 3, md: 6 },
          py: { xs: 4, md: 6 },
        }}
      >
        <div className="flex justify-center">
          <img src={"/assets/logo2.png"} alt="logo" className=" w-16 mb-3 " />
        </div>
        <DialogTitle
          sx={{
            m: 0,
            p: 0,
            pb: 2,
            fontSize: 16,
            textAlign: "center",
            color: "#374E5E",
          }}
        >
          {demoVideo ? "Login to watch this video" : "Login to your account"}
        </DialogTitle>
        <button
          onClick={() => handleGoogleSignInUp()}
          className="flex justify-center bg-[#E1E9EC] rounded-full py-3 my-4 text-center text-[#374E5E] text-sm w-full"
        >
          {isGoogleLoading ? (
            <div className="flex items-center gap-2 text-black">
              <CircularProgress sx={{ color: "black" }} size={14} />
              Processing...
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <FaGoogle />
              Continue with Google
            </div>
          )}
        </button>
        <hr />
        <form onSubmit={handleLogin}>
          <input
            type="text"
            id="username"
            placeholder="Username or Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            class="bg-white border outline-none border-gray-300 wtext-gray-900 text-sm rounded-full block w-full p-2.5 pl-5 mt-4"
            required
          />
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              class="bg-white border outline-none border-gray-300 text-gray-900 text-sm rounded-full block w-full p-2.5 pl-5 mt-2"
              required
            />
            <div
              className="absolute top-0.5 right-0 p-3 cursor-pointer"
              onClick={handleTogglePasswordVisibility}
            >
              {showPassword ? (
                <IoEyeOff className="text-gray-400" />
              ) : (
                <IoEye className="text-gray-400" />
              )}
            </div>
          </div>

          <div>
            {error && (
              <p className="text-red-500 text-[14px] pt-3 pl-2">{error}</p>
            )}
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="bg-[#374E5E] rounded-full py-3 my-4 text-center text-sm w-full text-white"
          >
            {isLoading ? (
              <div className="flex items-center justify-center ">
                <CircularProgress
                  size={20}
                  sx={{ color: "#FFFFFF", fontSize: "8px!important" }}
                />
              </div>
            ) : (
              <span className="text-md font-bold">Login</span>
            )}
          </button>
        </form>
        <div className="flex justify-center">
          <Link
            to="/lost-password"
            onClick={onClose}
            className="mb-2 text-sm text-[#374E5E]"
          >
            Forgot password?
          </Link>
        </div>

        <hr />

        <div className="flex justify-center gap-1 text-center text-sm text-[#19242b] mt-2">
          <span> Don't have an account?</span>
          <div onClick={handleNavigate}>
            <p className="text-sm font-bold">Register now</p>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};
